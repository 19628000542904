<template>
  <q-form ref="editForm">
    <c-card class="cardClassDetailForm" title="상세">
      <template slot="card-button">
        <q-btn-group outline>
          <c-btn
            v-show="editable && !disabled && isOld"
            label="삭제"
            icon="delete"
            @btnClicked="deleteLawCheck"
          />
          <c-btn
            v-show="editable && !disabled"
            :isSubmit="isSave"
            :url="saveUrl"
            :param="lawCheck"
            :mappingType="mappingType"
            label="저장"
            icon="save"
            @beforeAction="saveLawCheck"
            @btnCallback="saveLawCheckCallback"
          />
          <c-btn
            v-show="requestBtnEditable"
            :isSubmit="isRequest"
            :url="completeUrl"
            :param="lawCheck"
            mappingType="PUT"
            label="검토요청"
            icon="check"
            @beforeAction="requestLawCheck"
            @btnCallback="requestLawCheckCallback"
          />
          <c-btn
            v-show="completeBtnEditable"
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="lawCheck"
            mappingType="PUT"
            label="완료"
            icon="check"
            @beforeAction="completeLawCheck"
            @btnCallback="completeLawCheckCallback"
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
          <c-field
            :required="true"
            :disabled="disabled"
            :editable="editable"
            :data="lawCheck"
            deptValue="managerDeptCd"
            type="dept_user"
            label="담당자"
            name="managerId"
            v-model="lawCheck.managerId"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-dept-multi
            :required="true"
            :disabled="disabled"
            :editable="editable"
            :parentCheckDepts="lawCheck.acceptDeptCds"
            label="적용부서"
            name="acceptDeptCds"
            v-model="lawCheck.acceptDeptCds"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
          <c-text
            :disabled="disabled"
            :editable="editable"
            label="해당시설"
            name="applicableFacility"
            v-model="lawCheck.applicableFacility"
          >
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="현행 법규내용 요약"
            name="currentLawContents"
            v-model="lawCheck.currentLawContents"
          >
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="개정 법규내용 요약"
            name="reLawContents"
            v-model="lawCheck.reLawContents"
          >
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="검토요청내용"
            name="reviewRequestContents"
            v-model="lawCheck.reviewRequestContents"
          >
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="비고"
            name="remark"
            v-model="lawCheck.remark"
          >
          </c-textarea>
        </div>
      </template>
    </c-card>
    <c-card class="cardClassDetailForm q-mt-sm q-mb-sm" noHeader>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <c-text
                :required="true"
                :readonly="true"
                :editable="editable"
                :appendIcon="[
                  { name: 'search', click: true, callbackName: 'searchLaw' },
                ]"
                label="법령명(약칭)"
                name="lawNameKor"
                v-model="lawCheck.lawNameKor"
                @searchLaw="searchLaw"
              >
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <c-text
                :editable="editable"
                :readonly="true"
                label="공포일자"
                name="promulgationDate"
                v-model="lawCheck.promulgationDate"
              >
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <c-text
                :editable="editable"
                :readonly="true"
                label="제개정"
                name="revisiontypename"
                v-model="lawCheck.revisiontypename"
              >
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <c-text
                :editable="editable"
                :readonly="true"
                label="소관부처"
                name="competentMinistriesName"
                v-model="lawCheck.competentMinistriesName"
              >
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <c-text
                :editable="editable"
                :readonly="true"
                label="법령구분"
                name="lawClassName"
                v-model="lawCheck.lawClassName"
              >
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <c-text
                :editable="editable"
                :readonly="true"
                label="시행일자"
                name="enforcementDate"
                v-model="lawCheck.enforcementDate"
              >
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <q-chip
                v-if="lawCheck.mdmLawId"
                outline
                square
                color="teal"
                text-color="white"
                icon="open_in_new"
                clickable
                class="cursor-pointer"
                @click="fileDown"
                >{{ $language("법령안") }}</q-chip
              >
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <c-upload :attachInfo="attachInfo" :editable="editable && !disabled">
          </c-upload>
        </div>
      </template>
    </c-card>
    <c-table
      ref="table"
      title="검토자 목록"
      :columns="userTableColumns"
      :data="lawCheck.checkUsers"
      :gridHeight="gridHeight"
      :editable="editable && !disabled"
      :hideBottom="true"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="false"
      :columnSetting="false"
      selection="multiple"
      rowKey="mdmLawCheckUserId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn
            v-if="editable && !disabled && lawCheck.checkUsers.length > 0"
            label="제외"
            icon="remove"
            @btnClicked="deleteChecker"
          />
          <c-btn
            v-if="editable && !disabled"
            label="추가"
            icon="add"
            @btnClicked="addChecker"
          />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from "quasar";
import selectConfig from "@/js/selectConfig";
import transactionConfig from "@/js/transactionConfig";
export default {
  name: "law-review-detail",
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmLawCheckId: "",
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      lawCheck: {
        /** 입법예고검토 정보 */
        mdmLawCheckId: "", // 법령검토 번호
        lawId: "", // 법령ID
        mdmLawId: "", // 시행일 법령 번호
        mdmLawStepCd: "", // 진행단계
        managerId: "", // 담당자id
        acceptDeptCds: "", // 적용부서
        currentLawContents: "", // 현행 법규내용 요약
        reLawContents: "", // 개정 법규내용 요약
        applicableFacility: "", // 해당시설
        reviewRequestContents: "", // 검토요청내용
        remark: "", // 비고
        /** 입법예고 정보 */
        lawSerialNum: "", // 법령일련번호
        curHistroyCode: "", // 현행연혁코드
        lawNameKor: "", // 법령명한글
        lawNameAbb: "", // 법령약칭명
        promulgationDate: "", // 공포일자
        promulgationNum: "", // 공포번호
        revisiontypename: "", // 제개정구분명
        competentMinistriesCode: "", // 소관부처코드
        competentMinistriesName: "", // 소관부처명
        lawClassName: "", // 법령구분명
        enforcementDate: "", // 시행일자
        lawTypeFlag: "", // 자법타법여부
        lawLink: "", // 법령상세링크
        regUserId: "", // 등록자 ID
        chgUserId: "", // 수정자 ID
        checkUsers: [], // 검토자들
        deleteCheckUsers: [], // 검토자들 [삭제]
      },
      attachInfo: {
        isSubmit: "", // 판정에서 데이터가 저장되어 키가 있는 상황임으로 사용하지 않음
        taskClassCd: "LAW_CHECK",
        taskKey: "",
      },
      popupOptions: {
        isFull: false,
        width: "85%",
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      editable: true,
      isSave: true,
      isRequest: true,
      isComplete: true,
      detailUrl: "",
      saveUrl: "",
      deleteUrl: "",
      completeUrl: "",
      mappingType: "PUT",
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
    isOld() {
      return Boolean(this.popupParam.mdmLawCheckId);
    },
    disabled() {
      return this.lawCheck.mdmLawStepCd === "LS00000010";
    },
    requestBtnEditable() {
      return (
        this.editable &&
        !this.disabled &&
        this.lawCheck.mdmLawStepCd === "LS00000001" &&
        this.isOld
      );
    },
    completeBtnEditable() {
      return (
        this.editable &&
        !this.disabled &&
        this.lawCheck.mdmLawStepCd === "LS00000005" &&
        this.isOld
      );
    },
    gridHeight() {
      return this.contentHeight ? this.contentHeight - 550 + "px" : "500px";
    },
    userTableColumns() {
      let _item = [
        {
          name: "checkDeptName",
          field: "checkDeptName",
          label: "부서",
          align: "center",
          style: "width:100px",
          sortable: true,
        },
        {
          name: "checkUserName",
          field: "checkUserName",
          label: "검토자",
          align: "center",
          style: "width:100px",
          sortable: true,
        },
      ];
      if (this.isOld && this.lawCheck.mdmLawStepCd !== "LS00000001") {
        _item.push({
          name: "remark",
          field: "remark",
          label: "검토의견",
          align: "left",
          style: "width:400px",
          sortable: true,
          type: "textarea",
        });
      }
      return _item;
    },
  },
  watch: {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.lawCheck.check.get.url;
      this.saveUrl = transactionConfig.sai.lawCheck.check.insert.url;
      this.deleteUrl = transactionConfig.sai.lawCheck.check.delete.url;
      this.completeUrl = transactionConfig.sai.lawCheck.check.complete.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.mdmLawCheckId) {
        this.$http.url = this.$format(
          this.detailUrl,
          this.popupParam.mdmLawCheckId
        );
        this.$http.type = "GET";
        this.$http.request((_result) => {
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit("setRegInfo", _result.data);

          this.$_.extend(this.lawCheck, _result.data);
          this.$set(this.attachInfo, "taskKey", this.popupParam.mdmLawCheckId);
        });
      } else {
        this.$set(this.lawCheck, "mdmLawStepCd", "LS00000001");
        this.$set(this.lawCheck, "managerId", this.$store.getters.user.userId);
        this.$set(
          this.lawCheck,
          "managerDeptCd",
          this.$store.getters.user.deptCd
        );
      }
    },
    searchLaw(icon) {
      if (icon) {
        this.popupOptions.title = "법령 검토";
        this.popupOptions.param = {
          type: "single",
        };
        this.popupOptions.target = () =>
          import(`${"@/pages/sai/law/lawPop.vue"}`);
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeLawPopup;
      }
    },
    closeLawPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.extend(this.lawCheck, data[0]);
      }
    },
    fileDown() {
      window.open(
        "http://www.law.go.kr" + this.lawCheck.lawLink,
        "법규",
        "width=1200, height=700"
      );
    },
    addChecker() {
      this.popupOptions.title = "사용자 검색";
      this.popupOptions.param = {
        type: "multiple",
      };
      this.popupOptions.target = () =>
        import(`${"@/pages/common/user/userPop.vue"}`);
      this.popupOptions.width = "80%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let _data = [];
        this.$_.forEach(data, (item) => {
          if (
            this.$_.findIndex(this.lawCheck.checkUsers, {
              checkUserId: item.userId,
            }) === -1
          ) {
            _data.push({
              mdmLawCheckUserId: uid(), // 법령검토자 번호
              mdmLawCheckId: this.popupParam.mdmLawId, // 법령검토 번호
              checkDeptCd: item.deptCd,
              checkDeptName: item.deptName,
              checkUserId: item.userId,
              checkUserName: item.userName,
              remark: "",
              finishFlag: "N",
              editFlag: "C",
              regUserId: this.$store.getters.user.userId,
            });
          }
        });
        this.lawCheck.checkUsers = this.$_.concat(
          this.lawCheck.checkUsers,
          _data
        );
      }
    },
    deleteChecker() {
      let selectData = this.$refs[`table`].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내",
          message: "선택된 항목이 없습니다.",
          type: "warning", // success / info / warning / error
        });
      } else {
        window.getApp.$emit("CONFIRM", {
          title: "확인",
          message: "제외하시겠습니까?",
          type: "info", // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.lawCheck.deleteCheckUsers)
              this.lawCheck.deleteCheckUsers = [];
            this.$_.forEach(selectData, (item) => {
              if (
                item.editFlag !== "C" &&
                this.$_.findIndex(this.lawCheck.deleteCheckUsers, {
                  mdmLawCheckUserId: item.mdmLawCheckUserId,
                })
              ) {
                this.lawCheck.deleteCheckUsers.push(item);
              }
              this.lawCheck.checkUsers = this.$_.reject(
                this.lawCheck.checkUsers,
                item
              );
            });
            this.$refs[`table`].$refs["compo-table"].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {},
        });
      }
    },
    saveLawCheck() {
      if (this.popupParam.mdmLawCheckId) {
        this.mappingType = "PUT";
        this.saveUrl = transactionConfig.sai.lawCheck.check.update.url;
      } else {
        this.mappingType = "POST";
        this.saveUrl = transactionConfig.sai.lawCheck.check.insert.url;
      }

      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
          window.getApp.$emit("CONFIRM", {
            title: "확인",
            message: "저장하시겠습니까?",

            type: "info", // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.lawCheck.regUserId = this.$store.getters.user.userId;
              this.lawCheck.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    saveLawCheckCallback(_result) {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.$set(this.popupParam, "mdmLawCheckId", _result.data);
      this.$set(this.attachInfo, "taskKey", _result.data);
      this.$set(this.attachInfo, "isSubmit", uid());
      this.getDetail();
    },
    requestLawCheck() {
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
          window.getApp.$emit("CONFIRM", {
            title: "확인",
            message: "검토요청하시겠습니까?",

            type: "info", // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.lawCheck.chgUserId = this.$store.getters.user.userId;

              this.isRequest = !this.isRequest;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    requestLawCheckCallback() {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.getDetail();
    },
    completeLawCheck() {
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
          window.getApp.$emit("CONFIRM", {
            title: "확인",
            message: "완료하시겠습니까?",

            type: "info", // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.lawCheck.chgUserId = this.$store.getters.user.userId;

              this.isComplete = !this.isComplete;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    completeLawCheckCallback() {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.getDetail();
    },
    deleteLawCheck() {
      window.getApp.$emit("CONFIRM", {
        title: "확인",
        message: "삭제하시겠습니까?",

        type: "info", // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(
            this.deleteUrl,
            this.popupParam.mdmLawCheckId
          );
          this.$http.type = "DELETE";
          this.$http.request(() => {
            window.getApp.$emit("APP_REQUEST_SUCCESS");
            this.$emit("closePopup");
          });
        },
        // 취소 callback 함수
        cancelCallback: () => {},
      });
    },
  },
};
</script>